import { FC } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { IRecipeThumb } from '@modules/collections/domain/models/newCollection.model';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import { CollectionLink, Container, ContentWrapper, MenuAmount, Name, RecipeItem, RecipeList, Title } from './Cover.style';
interface Props {
  name: string;
  menuAmount: number;
  imageUrl: string;
  videoUrl: string;
  slug: string;
  recipeList: IRecipeThumb[];
  nameSize?: number;
  nameLineHeight?: number;
  menuAmountSize?: number;
}
const CollectionCover: FC<Props> = ({
  menuAmount,
  name,
  imageUrl,
  videoUrl,
  slug,
  recipeList,
  nameSize,
  nameLineHeight,
  menuAmountSize
}: Props) => {
  const router = useRouter();
  const utag = useUtag();
  return <Container $imageUrl={imageUrl}>
            <div className="overlay-cover" />
            {videoUrl && <video className="background-vdo" playsInline autoPlay muted loop>
                    <source src={videoUrl} type="video/mp4" />
                </video>}
            <ContentWrapper>
                <CollectionLink href={`/collection/${slug}`} aria-label="collection detail" onClick={() => utag.link({
        tealium_event: 'collection_thumbnail_l_click'
      })} />
                <Title>
                    <MenuAmount $menuAmountSize={menuAmountSize}>
                        {menuAmount} เมนู
                    </MenuAmount>
                    <Name $nameSize={nameSize} $nameLineHeight={nameLineHeight}>
                        {name}
                    </Name>
                </Title>
                <RecipeList>
                    {recipeList.map((recipe, index) => {
          return <RecipeItem key={index} onClick={() => {
            utag.link({
              tealium_event: 'collection_thumbnail_s_click'
            });
            router.push(`/recipe/${recipe.slug}`);
          }}>
                                <Image src={recipe.imageUrl || '/images/default/recipe.jpg'} alt={recipe.slug} onError={({
              currentTarget
            }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/default/recipe.jpg';
            }} width={106} height={106} />
                            </RecipeItem>;
        })}
                </RecipeList>
            </ContentWrapper>
        </Container>;
};
export default CollectionCover;